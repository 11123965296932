var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "modal-rename-value",
    attrs: {
      id: "modal-rename-value",
      "footer-class": "flex-nowrap",
      "no-close-on-backdrop": "",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [
            _c("h3", [_vm._v(_vm._s(_vm.$t("modals.renameContact.title")))])
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("div", [
              _c(
                "div",
                { staticClass: "mt-4 mx-5 mb-3" },
                [
                  _c("label", { attrs: { for: "value-name" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("modals.renameContact.name")) + " "
                    )
                  ]),
                  _c("b-form-input", {
                    attrs: { id: "value-name", autofocus: "", debounce: "500" },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.enterPressed.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mx-5 mb-4" },
                [
                  _c("label", { attrs: { for: "value-id" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("modals.renameContact.id")) + " "
                    )
                  ]),
                  _c("b-form-input", {
                    attrs: { id: "value-id", debounce: "500", disabled: "" },
                    on: {
                      input: _vm.idChangedByUser,
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.enterPressed.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.id,
                      callback: function($$v) {
                        _vm.id = $$v
                      },
                      expression: "id"
                    }
                  })
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            _c(
              "b-button",
              {
                staticClass: "p-3 border-right-light-grey",
                attrs: { block: "" },
                on: { click: _vm.closeModal }
              },
              [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
            ),
            _c(
              "b-button",
              {
                staticClass: "p-3",
                attrs: {
                  disabled: !_vm.isValidated,
                  variant: "primary",
                  block: ""
                },
                on: { click: _vm.renameValue }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("modals.renameContact.rename")) + " "
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }