<template>
  <b-modal
      id="modal-rename-value"
      ref="modal-rename-value"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.renameContact.title') }}</h3>
    </template>

    <template #default>
      <div>
        <div class="mt-4 mx-5 mb-3">
          <label for="value-name">
            {{ $t('modals.renameContact.name') }}
          </label>
          <b-form-input
              id="value-name"
              v-model="name"
              autofocus
              debounce="500"
              @keyup.enter="enterPressed"
          >
          </b-form-input>
        </div>
        <div class="mx-5 mb-4">
          <label for="value-id">
            {{ $t('modals.renameContact.id') }}
          </label>
          <b-form-input
              id="value-id"
              v-model="id"
              debounce="500"
              disabled
              @input="idChangedByUser"
              @keyup.enter="enterPressed"
          >
          </b-form-input>
        </div>
      </div>
    </template>

    <template #modal-footer>
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="closeModal"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          :disabled="!isValidated"
          variant="primary"
          class="p-3"
          block
          @click="renameValue"
      >
        {{ $t('modals.renameContact.rename') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import slugify from '@sindresorhus/slugify';

export default {
  name: 'ModalRenameContact',
  props: {
    nameProp: {
      type: String,
      required: true,
    },
    idProp: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    name: null,
    id: null,
    idChangedManually: false,
    oldId: null,
  }),
  computed: {
    isValidated() {
      return (this.name && this.id);
    },
  },
  created() {
    this.name = this.nameProp;
    this.id = this.idProp;
    this.oldId = this.idProp;
  },
  mounted() {
    this.$refs['modal-rename-value'].show();
  },
  methods: {
    renameValue() {
      console.log('renameValue', this.name, this.id);
      if (this.name && this.id) {
        this.$emit('rename-value', {
          name: this.name,
          id: this.id,
          oldId: this.oldId,
        });
        this.$refs['modal-rename-value'].hide();
      }
    },
    closeModal() {
      this.$refs['modal-rename-value'].hide();
      this.$emit('close-create-value-modal');
    },
    idChangedByUser() {
      this.id = slugify(this.id);
    },
    enterPressed() {
      if (this.name && this.id) {
        this.renameValue();
      }
    },
  },
};
</script>

<style scoped lang="scss">
$primary-color: #9A9DA0;

button[disabled="disabled"] {
  cursor: not-allowed;
}

.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .input-container {
    position: relative;
    height: 78px;
    width: calc(50% - .5rem);
    margin: 0.5rem;

    &:nth-child(even) {
      margin-right: 0;
    }

    &:nth-child(odd) {
      margin-left: 0;
    }

    h4 {
      color: #9A9DA0;
      font-size: 15px;
      font-weight: 600;
    }

    p {
      color: #9A9DA0;
      font-size: 13px;
    }

    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    //default tile styles
    .radio-tile {
      width: 100%;
      height: 100%;
      border: 1px solid #D0D4D8;
      padding: 10px 15px;

      .icon-wrapper {
        position: relative;
        float: left;
        top: -3px;
      }

      .text-wrapper {
        padding-left: 25px;
      }
    }

    //active tile styles
    .radio-button:checked + .radio-tile {
      background-color: $primary-color;
      border: 2px solid $primary-color;
      color: white;

      .icon svg {
        fill: white;
        background-color: $primary-color;
      }

      h4, p {
        color: white;
        background-color: $primary-color;
      }
    }
  }
}
</style>
